/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Button, Subtitle, Text } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Podcasty"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="--center pb--60 pt--60" style={{"backgroundColor":"var(--color-blend--95)"}} name={"uvod"}>
          
          <ColumnWrap className="column__flex --left el--1 flex--center" style={{"maxWidth":1200}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s3 pb--0 pl--0 pr--0 pt--0" style={{"maxWidth":900}} anim={"2"} animS={"3"}>
              
              <Title className="title-box fs--62 w--900" style={{"maxWidth":700}} content={"Podcasty"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--25 pt--20" name={"talent-2404"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Image style={{"maxWidth":360}} src={"https://cdn.swbpg.com/t/25013/0cec87c6c959408d970faed75d95a1be_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/25013/0cec87c6c959408d970faed75d95a1be_s=350x_.jpg 350w"}>
              </Image>

              <Button className="btn-box btn-box--pbtn2 btn-box--shape4 btn-box--center fs--18 w--700 ls--0 lh--1" url={"Dnešný podcast predstavuje úryvok z knihy Jara Slávika – Talent. Táto kniha ponúka tri pohľady za oponu talentového priemyslu: prvý pohľad je o talentovom zábavnom priemysle, druhý pohľad je o hádkach a úspechoch populárnych tvárí a tretí pohľad je denník z nakrúcania Česko Slovensko má talent."} href={"Dnešný podcast predstavuje úryvok z knihy Jara Slávika – Talent. Táto kniha ponúka tri pohľady za oponu talentového priemyslu: prvý pohľad je o talentovom zábavnom priemysle, druhý pohľad je o hádkach a úspechoch populárnych tvárí a tretí pohľad je denník z nakrúcania Česko Slovensko má talent."} content={"&gt;&gt;&gt;"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--center pr--02" content={"Talent"}>
              </Title>

              <Subtitle className="subtitle-box" content={"Jaro Slávik"}>
              </Subtitle>

              <Text className="text-box text-box--left" style={{"maxWidth":1000}} content={"Dnešný podcast predstavuje úryvok z knihy Jara Slávika – Talent. Táto kniha ponúka tri pohľady za oponu talentového priemyslu: prvý pohľad je o talentovom zábavnom priemysle, druhý pohľad je o hádkach a úspechoch populárnych tvárí a tretí pohľad je denník z nakrúcania Česko Slovensko má talent. <a href=\"Dnešný podcast predstavuje úryvok z knihy Jara Slávika – Talent. Táto kniha ponúka tri pohľady za oponu talentového priemyslu: prvý pohľad je o talentovom zábavnom priemysle, druhý pohľad je o hádkach a úspechoch populárnych tvárí a tretí pohľad je denník z nakrúcania Česko Slovensko má talent.\" target=\"_blank\">&gt;&gt;&gt;</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--25 pt--20" name={"gloria-na-prazdninach-2403"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Image style={{"maxWidth":360}} src={"https://cdn.swbpg.com/t/25013/c55ad147fe11497e8d69512772c356f0_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/25013/c55ad147fe11497e8d69512772c356f0_s=350x_.jpg 350w"}>
              </Image>

              <Button className="btn-box btn-box--pbtn2 btn-box--shape4 btn-box--center fs--18 w--700 ls--0 lh--1" url={"https://youtu.be/i0besHx9Wec"} href={"https://youtu.be/i0besHx9Wec"} content={"&gt;&gt;&gt;"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--center pr--02" content={"Glória na prázdninách "}>
              </Title>

              <Subtitle className="subtitle-box" content={"Martina Juhász"}>
              </Subtitle>

              <Text className="text-box text-box--left" style={{"maxWidth":1000}} content={"Čítanie z knižky pre najmenších čitateľov Glória na prázdninách o tom, ako sa Glória vybrala s rodičmi na kúpalisko. <a href=\"https://youtu.be/5N8UKTkm6YM\" target=\"_blank\">&gt;&gt;&gt;</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--25 pt--20" name={"fantom-z-internetu-2403"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Image style={{"maxWidth":360}} src={"https://cdn.swbpg.com/t/25013/483b996372b7401da3a944e10992a8e3_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/25013/483b996372b7401da3a944e10992a8e3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/25013/483b996372b7401da3a944e10992a8e3_s=660x_.jpg 660w"}>
              </Image>

              <Button className="btn-box btn-box--pbtn2 btn-box--shape4 btn-box--center fs--18 w--700 ls--0 lh--1" url={"https://youtu.be/i0besHx9Wec"} href={"https://youtu.be/i0besHx9Wec"} content={"&gt;&gt;&gt;"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--center pr--02" content={"Fantóm z internetu "}>
              </Title>

              <Subtitle className="subtitle-box" content={"Simona Kutišová"}>
              </Subtitle>

              <Text className="text-box text-box--left" style={{"maxWidth":1000}} content={"Dnešným podcastom vám predstavíme knihu Fantóm z internetu od regionálnej autorky Simony Kutišovej, ktorej príbeh poukazuje na nástrahy číhajúce na sociálnych sieťach. <a href=\"https://youtu.be/i0besHx9Wec\" target=\"_blank\">&gt;&gt;&gt;</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--25 pt--20" name={"jankina-perinka-2402"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Image style={{"maxWidth":360}} src={"https://cdn.swbpg.com/t/25013/582b3a2b3269465384f85e0fec343eda_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/25013/582b3a2b3269465384f85e0fec343eda_s=350x_.jpg 350w"}>
              </Image>

              <Button className="btn-box btn-box--pbtn2 btn-box--shape4 btn-box--center fs--18 w--700 ls--0 lh--1" url={"https://youtu.be/bSylbj9aLTk"} href={"https://youtu.be/bSylbj9aLTk"} content={"&gt;&gt;&gt;"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--center pr--02" content={"Jankina perinka"}>
              </Title>

              <Subtitle className="subtitle-box" content={"Arthur Miller"}>
              </Subtitle>

              <Text className="text-box text-box--left" style={{"maxWidth":1000}} content={"Vypočujte si úryvok z rozprávky, ktorá je o dospievaní a o tom, ako sa z bábätka stáva dievča. <a href=\"https://youtu.be/bSylbj9aLTk\" target=\"_blank\">&gt;&gt;&gt;</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--25 pt--20" name={"carovny-malicek-2312"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Image style={{"maxWidth":360}} src={"https://cdn.swbpg.com/t/25013/d01e9b5082b741628246c8dd91d95700_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/25013/d01e9b5082b741628246c8dd91d95700_s=350x_.jpg 350w, https://cdn.swbpg.com/t/25013/d01e9b5082b741628246c8dd91d95700_s=660x_.jpg 660w"}>
              </Image>

              <Button className="btn-box btn-box--pbtn2 btn-box--shape4 btn-box--center fs--18 w--700 ls--0 lh--1" url={"https://youtu.be/JQGlSYrouDk"} href={"https://youtu.be/JQGlSYrouDk"} content={"&gt;&gt;&gt;"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--center pr--02" content={"Čarovný malíček"}>
              </Title>

              <Subtitle className="subtitle-box" content={"Karine-Marie Amiot"}>
              </Subtitle>

              <Text className="text-box text-box--left" style={{"maxWidth":1000}} content={"Vypočujte si rozprávku Čarovný malíček z knižky francúzskych rozprávok Najkrajšie rozprávky starej mamy od autorky Karine-Marie Amiot. <a href=\"https://youtu.be/JQGlSYrouDk\" target=\"_blank\">&gt;&gt;&gt;</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--25 pt--20" name={"profesori-zlocinu2311"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Image style={{"maxWidth":360}} src={"https://cdn.swbpg.com/t/25013/a6be3891c37a47c2a1bdbef57e464a4e_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/25013/a6be3891c37a47c2a1bdbef57e464a4e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/25013/a6be3891c37a47c2a1bdbef57e464a4e_s=660x_.jpg 660w"}>
              </Image>

              <Button className="btn-box btn-box--pbtn2 btn-box--shape4 btn-box--center fs--18 w--700 ls--0 lh--1" url={"https://youtu.be/gtssXFcs2lM"} href={"https://youtu.be/gtssXFcs2lM"} content={"&gt;&gt;&gt;"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--center pr--02" content={"Profesori zločinu"}>
              </Title>

              <Subtitle className="subtitle-box" content={"Peter Šloser"}>
              </Subtitle>

              <Text className="text-box text-box--left" style={{"maxWidth":1000}} content={"Vypočujte si podcast o knihe Profesori zločinu, ktorá nás prevedie nepoznaným svetom policajného zákulisia a zločinu s ktorým autor aktívne 30 rokov bojoval. <a href=\"https://youtu.be/gtssXFcs2lM\" target=\"_blank\">&gt;&gt;&gt;</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--25 pt--20" name={"precitaj-mi-rozpravku2310"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Image style={{"maxWidth":360}} src={"https://cdn.swbpg.com/t/25013/e743cc362d754442a05d40934437454f_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/25013/e743cc362d754442a05d40934437454f_s=350x_.jpg 350w"}>
              </Image>

              <Button className="btn-box btn-box--pbtn2 btn-box--shape4 btn-box--center fs--18 w--700 ls--0 lh--1" url={"https://youtu.be/1Z1flx06aO4"} href={"https://youtu.be/1Z1flx06aO4"} content={"&gt;&gt;&gt;"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--center pr--02" content={"Prečítaj mi rozprávku. <br>O Červenej čiapočke"}>
              </Title>

              <Subtitle className="subtitle-box" content={"Petr Šulc"}>
              </Subtitle>

              <Text className="text-box text-box--left" style={{"maxWidth":1000}} content={"Dnešný podcast je z knihy Prečítaj mi rozprávku od autora Petra Šulca. Započúvajte sa do príbehu O Červenej čiapočke.   Videopodcast si môžete pozrieť tu <a href=\"https://youtu.be/1Z1flx06aO4\" target=\"_blank\">&gt;&gt;&gt;</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--25 pt--20" name={"strateni-v-horach2310"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Image style={{"maxWidth":360}} src={"https://cdn.swbpg.com/t/25013/e4673d2de39f41b3a4a25e1adb6cdbc9_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/25013/e4673d2de39f41b3a4a25e1adb6cdbc9_s=350x_.jpg 350w"}>
              </Image>

              <Button className="btn-box btn-box--pbtn2 btn-box--shape4 btn-box--center fs--18 w--700 ls--0 lh--1" url={"https://youtu.be/Wfim_5P9DEs"} href={"https://youtu.be/Wfim_5P9DEs"} content={"&gt;&gt;&gt;"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--center pr--02" content={"Stratení v horách"}>
              </Title>

              <Subtitle className="subtitle-box" content={"Miloš Jesenský a <br>Robert K. Leśniakiewicz"}>
              </Subtitle>

              <Text className="text-box text-box--left" style={{"maxWidth":1000}} content={"Vitajte pri našom podcaste, ktorý sa venuje knihe Stratení v horách od autorov Miloš Jesenský a Robert K. Leśniakiewicz. Ponorte sa spolu s nami do úryvku z tejto fascinujúcej detektívky. Knihu si je možné požičať v Tribečskej knižnici v Topoľčanoch na úseku beletrie.  Videopodcast si môžete pozrieť tu <a href=\"https://youtu.be/_Dn1pMMHx1o\" target=\"_blank\">&gt;&gt;&gt;</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--25 pt--20" name={"zivot-a-poslanie-ladislava-dorusaka-2-2310"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Image style={{"maxWidth":360}} src={"https://cdn.swbpg.com/t/25013/1ee88a055e104a28abaadbbeeacea0b1_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/25013/1ee88a055e104a28abaadbbeeacea0b1_s=350x_.jpg 350w"}>
              </Image>

              <Button className="btn-box btn-box--pbtn2 btn-box--shape4 btn-box--center fs--18 w--700 ls--0 lh--1" url={"https://youtu.be/Wfim_5P9DEs"} href={"https://youtu.be/Wfim_5P9DEs"} content={"&gt;&gt;&gt;"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--center pr--02" content={"Život a poslanie kňaza Ladislava Dorušáka"}>
              </Title>

              <Subtitle className="subtitle-box" content={"2. časť"}>
              </Subtitle>

              <Text className="text-box text-box--left" style={{"maxWidth":1000}} content={"Dokončenie rozprávania Mgr. Slavomíry Peťovskej o kanonikovi ThDr. Ladislavovi Dorušákovi pri príležitosti stého výročia jeho narodenia. Videopodcast si môžete pozrieť tu <a href=\"https://youtu.be/Wfim_5P9DEs\" target=\"_blank\">&gt;&gt;&gt;</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--20 pt--20" name={"zivot-a-poslanie-ladislava-dorusaka-2309"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Image style={{"maxWidth":360}} src={"https://cdn.swbpg.com/t/25013/59af8c92d2a9450fa28320d332a6a6bd_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/25013/59af8c92d2a9450fa28320d332a6a6bd_s=350x_.jpg 350w, https://cdn.swbpg.com/t/25013/59af8c92d2a9450fa28320d332a6a6bd_s=660x_.jpg 660w, https://cdn.swbpg.com/t/25013/59af8c92d2a9450fa28320d332a6a6bd_s=860x_.jpg 860w, https://cdn.swbpg.com/t/25013/59af8c92d2a9450fa28320d332a6a6bd_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/25013/59af8c92d2a9450fa28320d332a6a6bd_s=2000x_.jpg 2000w"}>
              </Image>

              <Button className="btn-box btn-box--pbtn2 btn-box--shape4 btn-box--center fs--18 w--700 ls--0 lh--1" url={"https://youtu.be/gR6Zfe7hOlg"} href={"https://youtu.be/gR6Zfe7hOlg"} content={"&gt;&gt;&gt;"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--center pr--02" content={"Život a poslanie kňaza Ladislava Dorušáka"}>
              </Title>

              <Subtitle className="subtitle-box" content={"1. časť"}>
              </Subtitle>

              <Text className="text-box text-box--left" style={{"maxWidth":1000}} content={"Tribečská knižnica v Topoľčanoch prichádza s novou formou podávania informácií svojim čitateľom - podcastami. Prvým počinom je video podcast (1. časť) k 100. výročiu narodenia kanonika ThDr. Ladislava Dorušáka. Rozpráva Mgr. Slavomíra Peťovská.&nbsp; Videopodcast si môžete pozrieť tu <a href=\"https://youtu.be/gR6Zfe7hOlg\" target=\"_blank\">&gt;&gt;&gt;</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"obsah"}>
        </Column>


        <Column name={"sluzby-2"} layout={"l30"}>
        </Column>


        <Column className="pb--80 pt--80" style={{"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"kontakt"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3" anim={"7"} animS={"3"}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--20 pt--20" style={{"backgroundColor":"var(--color-supplementary)"}} name={"paticka"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--left fs--20" content={"<span style=\"color: var(--color-dominant)\">Tribečská knižnica v Topoľčanoch</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--right fs--20" content={"<span style=\"color: var(--color-dominant)\">2023&nbsp;</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}